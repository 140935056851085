import React from "react"
import tw from "twin.macro"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTwitter, faDiscord} from "@fortawesome/free-brands-svg-icons"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"

export interface SocialIconProps {
  social: "twitter" | "discord"
  href: string
  size: SizeProp
}

const icons = {
  "twitter": faTwitter,
  "discord": faDiscord
}

const FullIcon = tw(FontAwesomeIcon)`w-full h-full`

export function SocialIcon(props: SocialIconProps & React.HTMLAttributes<HTMLAnchorElement>) {
  const {
    social,
    href,
    size,
    ...rest
  } = props
  const icon = icons[social]

  return (
    <a href={href} {...rest}>
      <FullIcon icon={icon} size={size}/>
    </a>
  )
}