import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import tw from "twin.macro"
import { WithSidebar } from "./WithSidebar"
import { Logo } from "./Logo"
import { Navigation } from "./Navigation"
import { SocialIcon } from "./SocialIcon"


const Page = tw.div`font-sans`
const LogoContainer = tw.div`flex flex-row w-full items-center sm:flex-col`
const SidebarContainer = tw.div`sm:h-screen flex flex-row sm:flex-col justify-between p-2`
const SidebarBottomLinksContainer = tw.div`hidden sm:flex flex-col`
const SidebarSocialLinksContainer = tw.div`p-2 flex flex-row justify-around`
const CopyrightNotice = tw.p`text-xs text-gray-500 text-center`
const MainContainer = tw.div`sm:h-screen`

export interface LayoutProps {
  children: React.ReactElement[] | React.ReactElement
}

interface CompleteSidebarProps {
  siteMetadata: {
    discordUrl: string
    twitterUrl: string
  }
}

function CompleteSidebar({siteMetadata}: CompleteSidebarProps) {
  const SocialIconWithColor = tw(SocialIcon)`w-12 h-12 flex items-center justify-center text-gray-500`
  const DiscordSocialIcon = tw(SocialIconWithColor)`hover:text-purple-500`
  const TwitterSocialIcon = tw(SocialIconWithColor)`hover:text-blue-500`

  return (
    <SidebarContainer>
      <LogoContainer>
        <Logo />
        <Navigation />
      </LogoContainer>
      <SidebarBottomLinksContainer>
        <SidebarSocialLinksContainer>
          <DiscordSocialIcon social="discord" href={siteMetadata.discordUrl} size="2x"/>
          <TwitterSocialIcon social="twitter" href={siteMetadata.twitterUrl} size="2x"/>
        </SidebarSocialLinksContainer>
        <CopyrightNotice>&copy; 2021 Long Rock Labs</CopyrightNotice>
      </SidebarBottomLinksContainer>
    </SidebarContainer>
  )
}

export function Layout({ children }: LayoutProps) {
  return (
    <StaticQuery
      query={graphql`
          {
            site {
              siteMetadata {
                twitterUrl
                discordUrl
              }
            }
          }
        `}
      render={data => (
        <Page>
          <WithSidebar side={
            <CompleteSidebar siteMetadata={data.site.siteMetadata} />
          }>
            <MainContainer>
              {children}
            </MainContainer>
          </WithSidebar>
        </Page>
      )} />
  )
}
