import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"


const Container = tw.div`flex flex-row w-full sm:flex-col justify-between`
const NavigationContainer = tw.nav`flex flex-row items-center w-full`
const NavigationItemsContainer = tw.ul`py-0 sm:py-8 w-full text-center flex flex-row sm:flex-col justify-around`
const NavLi = tw.li`font-display text-xl leading-loose`
const NavLink = tw(Link)`font-bold hover:text-green-400`

interface NavigationItemProps {
  title: string
  link: string
}

function NavigationItem({ title, link }: NavigationItemProps) {
  return (
    <NavLi>
      <NavLink to={link}>{title}</NavLink>
    </NavLi>
  )
}

export function Navigation() {
  return (
    <Container>
      <NavigationContainer>
        <NavigationItemsContainer>
          <NavigationItem title="About" link="/about" />
          <NavigationItem title="Articles" link="/articles" />
        </NavigationItemsContainer>
      </NavigationContainer>
    </Container>
  )
}

