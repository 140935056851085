import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import { StaticImage } from "gatsby-plugin-image"

const LogoContainer = tw.div`max-w-tiny sm:max-w-full p-4 sm:p-12 pb-0 sm:pb-0`

export function Logo() {
  return (
    <LogoContainer>
      <Link to="/">
        <StaticImage alt="Long Rock Labs" src="../images/labs-logo.png" placeholder="tracedSVG" />
      </Link>
    </LogoContainer>
  )
}
