import React from "react"
import { Helmet } from "react-helmet"
import { IGatsbyImageData } from "gatsby-plugin-image"


export interface OpenGraphArticle {
    type: "article",
    title: string
    description?: string
    published_time?: Date
    author_twitter?: string
    twitter?: string
    image?: {
        url: string
        width: number
        height: number
    }
}

export type OpenGraph = OpenGraphArticle

export interface Item {
    type: "item"
    name: string
    content: string
}

export interface Property {
    type: "property"
    property: string
    content: string
}

export type Meta = Item | Property

export interface SEOProps {
    title: string
    description?: string
    meta: Meta[]
    openGraph?: OpenGraph
}

function openGraphMeta(og: OpenGraph): Meta[] {
    const meta = [{
        type: "property",
        property: "og:title",
        content: og.title
    }, {
        type: "item",
        name: "twitter:title",
        content: og.title
    }, {
        type: "property",
        name: "og:type",
        content: "article"
    }]

    if (!!og.description) {
        meta.push({
            type: "property",
            property: "og:description",
            content: og.description
        })
        meta.push({
            type: "item",
            name: "twitter:description",
            content: og.description
        })
    }

    if (!!og.published_time) {
        const published_time = new Date(og.published_time)
        meta.push({
            type: "item",
            property: "article:published_time",
            content: published_time.toISOString()
        })
    }

    if (!!og.author_twitter) {
        meta.push({
            type: "item",
            name: "twitter:creator",
            content: og.author_twitter
        })
    }

    if (!!og.twitter) {
        meta.push({
            type: "item",
            name: "twitter:site",
            content: og.twitter
        })
    }

    if (!!og.image) {
        meta.push({
            type: "item",
            name: "twitter:card",
            content: "summary_large_image"
        })
        meta.push({
            type: "item",
            name: "twitter:image",
            content: og.image.url//`https://ed145be81c2e.ngrok.io` + og.image.images.fallback?.src
        })
        meta.push({
            type: "property",
            property: "og:image",
            content: og.image.url
        })
        meta.push({
            type: "property",
            property: "og:image:width",
            content: og.image.width
        })
        meta.push({
            type: "property",
            property: "og:image:height",
            content: og.image.height
        })
    } else {
        meta.push({
            type: "item",
            name: "twitter:card",
            content: "summary"
        })
    }

    return meta
}

function renderMeta(meta: Meta, index: number) {
    if (meta.type == "item") {
        return <meta key={index} name={meta.name} content={meta.content} />
    } else {
        return <meta key={index} property={meta.property} content={meta.content} />
    }
}

const SEO: React.FC<SEOProps> = props => {
    const { title, description, meta, openGraph } = props

    const ogMeta = openGraph ? openGraphMeta(openGraph) : []

    return (
        <Helmet title={title}>
            <html lang="en" />
            {description ? <meta name="description" content={description} /> : null}
            {
                meta.map(renderMeta)
            }
            {
                ogMeta.map(renderMeta)
            }
        </Helmet>
    )
}

SEO.defaultProps = {
    description: undefined,
    meta: []
}

export default SEO
