import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"


const Container = tw.div`flex flex-col sm:flex-row`
const SidebarContainer = tw.div`w-full sm:max-w-2xs flex-grow sm:border-r-2`
const MainContent = tw.div`w-full overflow-y-auto`

export interface SidebarProps {
  side: React.ReactElement
  children: React.ReactElement[] | React.ReactElement
}

export function WithSidebar({ side, children }: SidebarProps) {
  return (
    <Container>
      <SidebarContainer>
        {side}
      </SidebarContainer>
      <MainContent>
        {children}
      </MainContent>
    </Container>
  )
}
